.login-container {
  width: 100%;
  max-width: 1120px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.login-container section.form {
  width: 100%;
  max-width: 350px;
  margin-right: 30px;
}

.login-container section.form form h1 {
  font-size: 32px;
  margin-bottom: 32px;
}