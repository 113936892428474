.sidenav .navbar-brand {
    padding: 1rem 1.5rem;
}

.navbar-vertical .navbar-brand>img,
.navbar-vertical .navbar-brand-img {
    max-height: 3rem;
}

.form-check-input::after {
    content: "X" !important;
}